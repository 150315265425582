<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-2 cursor-pointer">COMPRAS</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active" v-if="nuevaCompra"
        >ADMINISTRAR COMPRAS - RECEPCIÓN</span
      >
      <span class="text-900 line-height-3 active" v-else
        >RECIBIR ORDEN DE COMPRA</span
      >
    </li>
  </ul>
  <div
    v-if="!recibido && !cargandoDatos"
    class="surface-section px-4 py-2 md:px-12 lg:px-12"
  >
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h3 v-if="nuevaCompra" style="border-bottom: 1px solid #005f91">
        <strong>NUEVA ORDEN DE COMPRA - RECEPCIÓN </strong>
      </h3>
      <h3 v-else style="border-bottom: 1px solid #cecfd1">
        <strong>RECIBIR ORDEN DE COMPRA: #</strong> {{ ocId }}
      </h3>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid grid formgrid">
        <div class="field col-12 md:col-6">
          <div v-if="!nuevaCompra">
            <div v-if="!editandoProveedor" class="p-inputgroup">
              <span class="mr-2"><strong>PROVEEDOR:</strong> </span>
              <span
                class="text-900 mr-2"
                style="
                  background-color: white;
                  padding: 5px;
                  border-radius: 5px;
                "
                >{{ datosOc.nombre_proveedor }}</span
              >
              <Button
                icon="pi pi-pencil"
                class="p-button-info p-button-sm mr-2"
                v-tooltip.top="'Editar proveedor'"
                v-if="!nuevaCompra && !editandoProveedor"
                @click="editarProveedor"
              ></Button>
            </div>
            <div v-if="editandoProveedor" class="p-inputgroup">
              <span class="mr-2"><strong>PROVEEDOR:</strong> </span>
              <Dropdown
                v-if="editandoProveedor"
                v-model="proveedorSelect"
                :options="proveedores"
                :loading="loadingProveedores"
                optionLabel="nombre_completo"
                optionValue="id"
                placeholder="Seleccione un proveedor"
                class="p-inputtext-sm mr-2"
                filter
              />
              <Button
                icon="pi pi-save"
                class="p-button-info p-button-sm mr-2"
                v-tooltip.top="'Guardar proveedor'"
                @click="actualizarProveedorSolicitudOC"
                :loading="actualizandoProveedorSolicitud"
              />
              <Button
                icon="pi pi-times"
                class="p-button-danger p-button-sm"
                v-tooltip.top="'Cancelar'"
                @click="cancelarEdicionProveedor"
                :loading="actualizandoProveedorSolicitud"
              />
            </div>
          </div>
          <span v-else class="p-inputgroup">
            <span class="mr-2 mt-2"
              ><strong>PROVEEDOR:<span class="text-red-500">*</span> </strong>
            </span>
            <Dropdown
              v-model="proveedorSelect"
              :options="proveedores"
              optionLabel="nombre_completo"
              optionValue="id"
              placeholder="Seleccione un proveedor"
              class="p-inputtext-sm"
              filter
            />
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup">
            <span class="mr-2 mt-2"
              ><strong>SUCURSAL:<span class="text-red-500">*</span> </strong>
            </span>
            <Dropdown
              v-model="sucursalSelect"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
              placeholder="Seleccione una sucursal"
              class="p-inputtext-sm"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid formgrid grid mt-2">
      <div class="field col-12 md:col-8">
        <span
          ><strong
            >BUSCAR PRODUCTO | C&Oacute;D. ORIGEN. | DESCRIPCI&Oacute;N:</strong
          ></span
        >
        <div class="p-inputgroup">
          <AutoComplete
            v-model="productoSeleccionado"
            :disabled="buscando_producto"
            field="descripcion"
            :delay="500"
            placeholder="Buscar Producto por código o nombre"
            :suggestions="productos_encontrados"
            @complete="buscarProductoPorCodigoDescripcion"
            @item-select="agregarProductoDetalle"
            @keyup.enter="buscarProductoPorCodigoDescripcion"
            :dataKey="'id'"
            :filter="true"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.descripcion }} **
                <strong>{{ slotProps.item.fabrica_nombre }}**</strong
                ><span
                  :class="
                    stockClass(
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock_actual)
                        : 0
                    )
                  "
                  ><strong>{{
                    slotProps.item.stock.length > 0
                      ? sinDecimal(slotProps.item.stock_actual)
                      : 0
                  }}</strong></span
                >
              </div>
            </template>
          </AutoComplete>
          <Button
            icon="pi pi-plus"
            class="p-button-info"
            v-tooltip.top="'Nuevo Producto'"
            @click="activarProducto"
          />
        </div>
      </div>
      <div class="field col-12 md:col-2">
        <span><strong>RECIBO/FACTURA: </strong> </span>
        <InputNumber
          v-model="nro_recibo"
          placeholder="N° RECIBO"
          autofocus
          :min="0"
          :inputClass="'text-right'"
        />
      </div>
      <div class="field col-12 md:col-2">
        <span><strong>DESCUENTO: </strong> </span>
        <InputNumber
          v-model="descuento_oc"
          placeholder="DESCUENTO"
          input-class="text-right"
          :minFractionDigits="2"
          locale="de-DE"
          :min="0"
          :max="total_oc"
          @keyup.enter="repartir_descuento_a_productos_detalle"
        />
      </div>
    </div>
    <div class="grid">
      <DataTable
        ref="dtOC"
        :value="productos"
        editMode="cell"
        @cell-edit-complete="onChangeEditandoCelda"
        class="editable-cells-table p-datatable-sm col-12"
        stripedRows
        showGridlines
        responsiveLayout="scroll"
        selectionMode="single click"
      >
        <Column header="#" style="width: 8px">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column style="width: 10px">
          <template #header>
            <i class="pi pi-check"></i>
          </template>
          <template #body="slotProps">
            <Checkbox
              v-model="slotProps.data.verificado"
              value="verificado"
              v-tooltip.top="'Verificado'"
            />
          </template>
        </Column>
        <Column
          field="descripcion"
          header="DESCRIPCIÓN/PRODUCTO"
          class="p-inputtext-sm"
          style="font-weight: bold; width: 200px"
        >
          {{ data.descripcion }}
        </Column>
        <Column
          class="bg-green-200"
          field="pivot.cantidad"
          header="CANT."
          v-tooltip.top="'Cantidad Unitaria'"
          style="width: 10px; font-weight: bold; text-align: right"
        >
          <template #body="slotProps">
            {{ slotProps.data.pivot.cantidad }}
          </template>
          <template #editor="{ data }">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              locale="de-ED"
              v-model="data.pivot.cantidad"
              v-tooltip.top="'Cantidad Unitaria'"
              class="p-inputtext-sm"
            />
          </template>
        </Column>
        <Column
          class="text-right"
          field="pivot.precio"
          header="PU COMP"
          v-tooltip.top="'Precio Unitario de Compra'"
          style="width: 70px; font-weight: bold"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
          <template #editor="slotProps">
            <InputNumber
              v-model="slotProps.data.pivot.precio"
              autofocus
              :min="0"
              input-class="text-right"
              :minFractionDigits="2"
              locale="de-DE"
              v-tooltip.top="'Precio de compra'"
              class="p-inputtext-sm"
            />
          </template>
        </Column>
        <Column
          field="pivot.precio_unitario_descuento"
          class="text-right bg-gray-300 white-space-nowrap"
          header="PU DESC."
          style="width: 60px; font-weight: bold"
        >
          <template #body="{ data }">
            {{
              convertirNumeroGermanicFormat(
                data.pivot.precio_unitario_descuento == null
                  ? data.pivot.precio
                  : data.pivot.precio_unitario_descuento
              )
            }}
          </template>
        </Column>
        <Column
          field="pivot.descuento"
          class="text-right white-space-nowrap bg-yellow-100"
          header="DESC."
          style="width: 50px; font-weight: bold"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.pivot.descuento) }}
          </template>
          <template #editor="{ data }">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="2"
              locale="de-ED"
              v-model="data.pivot.descuento"
              autofocus
              v-tooltip.top="'Descuento'"
              class="p-inputtext-sm"
            />
          </template>
        </Column>
        <Column
          field="pivot.subtotal_descuento"
          class="text-right bg-gray-100 white-space-nowrap"
          header="SUB. DESC."
          style="width: 60px; font-weight: bold"
        >
          <template #body="{ data }">
            {{
              convertirNumeroGermanicFormat(
                data.pivot.sub_total - data.pivot.descuento
              )
            }}
          </template>
        </Column>
        <Column
          field="pivot.subtotal"
          class="text-right white-space-nowrap"
          header="SUBTOTAL"
          style="width: 60px; font-weight: bold"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.pivot.sub_total) }}
          </template>
          <template #editor="{ data }">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="2"
              locale="de-ED"
              v-model="data.pivot.sub_total"
              autofocus
              v-tooltip.top="'Subtotal'"
              class="p-inputtext-sm"
            />
          </template>
        </Column>
        <!-- <Column
            class="text-right"
            field="pivot.margen"
            header="MARGEN UNITARIO"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.pivot.margen) }}
            </template>
            <template #editor="{ data }">
              <InputNumber
                :inputClass="'text-right'"
                :min="0"
                :minFractionDigits="2"
                locale="de-ED"
                v-model="data.pivot.margen"
                autofocus
                v-tooltip.top="'Margen Unitario'"
              />
            </template>
          </Column> -->
        <Column
          class="text-right bg-green-200 white-space-nowrap"
          field="pivot.margen_porcentaje"
          header="GAN%"
          v-tooltip.top="'Margen Unitario %'"
          style="width: 60px"
        >
          <template #body="{ data }">
            {{
              convertirNumeroGermanicFormat(
                data.pivot.margen_porcentaje ?? null
              ) + " %"
            }}
          </template>
          <template #editor="{ data }">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="2"
              locale="de-ED"
              v-model="data.pivot.margen_porcentaje"
              autofocus
              v-tooltip.top="'Margen Unitario %'"
              class="p-inputtext-sm"
            />
          </template>
        </Column>
        <Column
          class="text-right bg-green-200 white-space-nowrap"
          field="pivot.precio_venta"
          header="PV"
          v-tooltip.top="'Precio de venta'"
          style="width: 40px"
        >
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.pivot.precio_venta)
            }}
          </template>
          <template #editor="{ data }">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="2"
              locale="de-ED"
              v-model="data.pivot.precio_venta"
              class="p-inputtext-sm"
            />
          </template>
        </Column>
        <Column style="width: 40px">
          <template #header>
            <i class="pi pi-cog"></i>
            ACCIONES
          </template>
          <template #body="slotProps">
            <div class="flex align-items-center justify-content-center">
              <Button
                :disabled="slotProps.data.pivot.cantidad == undefined"
                class="p-button-info p-button-lg p-button-rounded p-button-outlined mr-1"
                icon="pi pi-calendar-plus"
                v-tooltip.top="'Agregar Fecha Vencimiento'"
                @click="agregarFechaVencimiento(slotProps.data)"
              />
              <Button
                @click="quitar(slotProps)"
                v-tooltip.top="'Quitar Producto'"
                icon="pi pi-trash"
                class="p-button-danger p-button-lg p-button-rounded p-button-outlined"
              />
            </div>
          </template>
        </Column>
        <!--  <template #footer>
          <div class="text-right">
            <h5>
              <strong>Subtotal : </strong>
              {{ convertirNumeroGermanicFormat(total_oc) }} Bs.
            </h5>
          </div>
          <div class="text-right">
            <h5>
              <strong>Descuento :</strong>
              {{ convertirNumeroGermanicFormat(descuento_oc) }} Bs.
            </h5>
          </div>
          <div class="text-right">
            <h5>
              <strong>Total a Pagar :</strong>
              {{ convertirNumeroGermanicFormat(total_oc - descuento_oc) }}
              Bs.
            </h5>
          </div>
          <div class="text-right">
            <h5 v-if="tipo_pagoSelect == 2">
              <strong>A Cuenta :</strong>
              {{ convertirNumeroGermanicFormat(total_acuenta) }} Bs.
            </h5>
          </div>
          <div class="text-right">
            <h5 v-if="tipo_pagoSelect == 2">
              <strong>Saldo :</strong>
              {{
                convertirNumeroGermanicFormat(
                  total_oc - descuento_oc - total_acuenta
                )
              }}
              Bs.
            </h5>
          </div>
        </template> -->
      </DataTable>
    </div>
    <table
      class="table table-bordered table-striped"
      width="100%"
      cellspacing="0"
      style="font-size: 1.3rem"
    >
      <tbody class="text-right">
        <tr>
          <td class="text-right">
            <strong>Subtotal: </strong>
            {{ convertirNumeroGermanicFormat(total_oc) }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <strong>Descuento: </strong>
            <span class="text-green-500 font-bold">
              {{ convertirNumeroGermanicFormat(descuento_oc) }}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total a Pagar:</strong>
            {{ convertirNumeroGermanicFormat(total_oc - descuento_oc) }}
          </td>
        </tr>
        <tr v-if="tipo_pagoSelect == 2">
          <td>
            <strong>A Cuenta: </strong>
            <span class="text-green-500 font-bold">
              {{ convertirNumeroGermanicFormat(total_acuenta) }}
            </span>
          </td>
        </tr>
        <tr v-if="tipo_pagoSelect == 2">
          <td>
            <strong>Saldo: </strong>
            {{
              convertirNumeroGermanicFormat(
                total_oc - descuento_oc - total_acuenta
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row mt-2">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span
            ><strong
              >TIPO DE PAGO:
              <small v-if="tipo_pagoSelect == 2" class="text-red-500">
                PAGO AL CR&Eacute;DITO
              </small></strong
            ></span
          >
          <Dropdown
            id="tipo_pago"
            v-model="tipo_pagoSelect"
            :options="tipo_pagos"
            optionLabel="nombre"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span v-if="tipo_pagoSelect == 2"
            ><strong>D&Iacute;AS CR&Eacute;DITO:</strong></span
          >
          <InputNumber
            v-if="tipo_pagoSelect == 2"
            v-model="dias_credito"
            :min="1"
            :max="30"
            :inputClass="'text-right'"
            :minFractionDigits="0"
            locale="de-ED"
            :useGrouping="false"
            showButtons
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>M&Eacute;TODO DE PAGO:</strong></span>
          <Dropdown
            id="metodo_pago"
            v-model="metodo_pagoSelect"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-2" v-if="metodo_pagoSelect != 1">
          <span><strong>MONTO M&Eacute;TODO PAGO:</strong></span>
          <InputNumber
            v-model="a_cuenta_metodo_pago"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :max="total_oc - descuento_oc - efectivo"
          />
        </div>
        <div class="field col-12 md:col-2" v-if="metodo_pagoSelect != 1">
          <span><strong>EFECTIVO:</strong></span>
          <InputNumber
            v-model="efectivo"
            :min="0"
            :max="total_oc - descuento_oc - a_cuenta_metodo_pago"
            :inputClass="'text-right'"
            :minFractionDigits="2"
            locale="de-ED"
            :useGrouping="false"
            showButtons
          />
        </div>
        <div class="field col-12 md:col-4" v-if="metodo_pagoSelect == 1">
          <span><strong>EFECTIVO:</strong></span>
          <InputNumber
            v-model="efectivo"
            :min="0"
            :max="total_oc - descuento_oc - a_cuenta_metodo_pago"
            :inputClass="'text-right'"
            :minFractionDigits="2"
            locale="de-ED"
            :useGrouping="false"
            showButtons
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8">
          <span><strong>OBSERVACIONES:</strong></span>
          <Textarea
            v-model="observacion"
            required="true"
            rows="1"
            autoResize
            placeholder="Observaciones"
          >
          </Textarea>
        </div>

        <div class="field col-12 md:col-2">
          <span><strong>ACCIONES:</strong></span>
          <Button
            label="CANCELAR"
            icon="pi pi-times"
            class="p-button-secondary mr-2 p-button-lg"
            @click="goBack"
          >
          </Button>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ACCIONES:</strong></span>
          <Button
            label="GUARDAR"
            icon="pi pi-save"
            class="p-button-info p-button-lg"
            @click="recibirCompra"
            :loading="enviando"
            :disabled="enviando"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="grid" v-else>
    <div v-if="cargandoDatos" class="col-12">
      <div class="flex align-items-center justify-content-center">
        <ProgressSpinner />
      </div>
    </div>
    <Message v-else class="col-8" :severity="'info'" :closable="false">
      <div class="flex align-items-center justify-content-center">
        <span> Orden de Compra Recibida. </span>
        <Button
          label="Listado de Ordenes de Compra"
          class="p-button-rounded p-button-info p-button-sm ml-2"
          @click="this.$router.push({ name: 'compras' })"
        />
        <Button
          label="Ver Recepcion OC"
          class="p-button-rounded p-button-info p-button-sm ml-2"
          @click="this.$router.push(`/orden_compra/${datosOc.id}/detalle`)"
        />
      </div>
    </Message>
  </div>
  <FechaVence
    :show="nuevaFechaVence"
    :producto="productoFechas"
    @closeModal="closeModal"
  />
  <ProductoCreate
    :mostrar="productoModal"
    :producto="producto"
    @closeModal="cerrarModalProducto"
  >
  </ProductoCreate>
</template>

<script>
import OrdenCompraService from "@/service/OrdenCompraService";
import OCRecepcionService from "@/service/OCRecepcionService.js";
import SucursalService from "@/service/SucursalService.js";
import ProveedorService from "@/service/ProveedorService.js";
import FechaVence from "@/module/compras/FechaVence.vue";
import TipoPagoService from "@/service/TipoPagoService.js";
import MetodoPagoService from "@/service/MetodoPagoService.js";
import ProductService from "@/service/ProductService.js";
import ProductoCreate from "@/module/productos/ProductoCreate.vue";

import { round } from "lodash";
import { includes } from "lodash";
export default {
  components: {
    FechaVence,
    ProductoCreate,
  },
  data() {
    return {
      si_tiene_descuento: false,
      ocId: this.$route.params.id,
      nuevaCompra: false,
      editandoProveedor: false,
      loadingProveedores: false,
      actualizandoProveedorSolicitud: false,
      datosOc: {},
      productos: [],
      productoFechas: {},
      sucursalSelect: null,
      sucursales: [],
      errors: {},
      total_oc: 0,
      fecha_recepcion: null,
      enviando: false,
      recibido: false,
      cargandoDatos: true,
      grupoProductos: [],
      productoSeleccionado: null,
      nuevaFechaVence: false,
      nro_recibo: null,
      descuento_oc: 0,
      observacion: null,
      dias_credito: 0,
      efectivo: 0,
      tipo_pagoSelect: 2,
      tipo_pagos: [],
      precio_venta_producto: null,
      metodo_pagoSelect: 1,
      metodo_pagos: [],
      a_cuenta_metodo_pago: 0,
      buscando_producto: false,
      productos_encontrados: [],
      proveedorSelect: null,
      proveedores: [],
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      verificado: 0,
      productoModal: false,
      producto: { estado: { label: "Activo", value: 1 } },
      parametros_sistema: {},
      aplicaRedondeoPrecioVenta: false,
    };
  },
  ordenCompraService: null,
  ocRecepcionService: null,
  sucursalService: null,
  proveedorService: null,
  tipoPagoService: null,
  metodoPagoService: null,
  productService: null,
  created() {
    this.verificarNuevaCompra();
    this.ordenCompraService = new OrdenCompraService();
    this.ocRecepcionService = new OCRecepcionService();
    this.sucursalService = new SucursalService();
    this.proveedorService = new ProveedorService();
    this.tipoPagoService = new TipoPagoService();
    this.metodoPagoService = new MetodoPagoService();
    this.productService = new ProductService();
  },
  mounted() {
    /* this.fecha_recepcion = new Date();
    this.cargarSucursales(); */
    this.nuevaCompra ? this.cargarDatosNuevaCompra() : this.obtenerDatosOc();
    this.nuevaCompra ? this.cargarProveedores() : null;
    /* this.obtenerTiposPagos();
    this.getMetodoPagos(); */
    this.getDatosNecesariosOC();
  },
  computed: {
    total_acuenta() {
      return round(this.efectivo + this.a_cuenta_metodo_pago, 2);
    },
  },
  methods: {
    getDatosNecesariosOC() {
      this.ocRecepcionService.getDatosNecesariosOC().then((response) => {
        this.tipo_pagos = response.datos.tipo_pagos || [];
        this.metodo_pagos = response.datos.metodo_pagos || [];
        this.sucursales = response.datos.sucursales || [];
        this.sucursalSelect = this.sucursales[0].id || null;
        this.proveedores = response.datos.proveedores || [];
        this.parametros_sistema = response.datos.parametros_sistema || {};
        this.parametros_sistema.find(
          // Verificar si se aplica redondeo al precio de venta
          (parametro) => parametro.nombre == "Precio Venta Redondear"
        )
          ? (this.aplicaRedondeoPrecioVenta = true)
          : (this.aplicaRedondeoPrecioVenta = false);
      });
    },
    activarProducto() {
      this.productoModal = true;
      this.producto = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarModalProducto() {
      this.productoModal = false;
      this.producto = {};
    },
    actualizarProveedorSolicitudOC() {
      this.actualizandoProveedorSolicitud = true;
      this.ordenCompraService
        .actualizarProveedorSolicitudOC(this.datosOc.id, this.proveedorSelect)
        .then((response) => {
          this.datosOc.proveedor_id = response.solicitud_compra.proveedor_id;
          this.datosOc.nombre_proveedor =
            response.solicitud_compra.nombre_proveedor;
          this.editandoProveedor = false;
          this.$toast.add({
            severity: "success",
            summary: "Proveedor Actualizado",
            detail: "Proveedor Actualizado Correctamente",
            life: 10000,
          });
          this.actualizandoProveedorSolicitud = false;
        });
    },
    cancelarEdicionProveedor() {
      this.editandoProveedor = false;
      this.proveedorSelect = this.datosOc.proveedor_id;
    },
    editarProveedor() {
      this.editandoProveedor = true;
      this.cargarProveedores();
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    cargarProveedores() {
      this.loadingProveedores = true;
      this.proveedorService.getProveedores().then((response) => {
        this.proveedores = response.proveedores;
        this.loadingProveedores = false;
      });
    },
    verificarNuevaCompra() {
      this.nuevaCompra = this.ocId === "nueva_compra";
      this.recibido = false;
      this.cargandoDatos = false;
    },
    cargarDatosNuevaCompra() {
      /* console.log("cargarDatosNuevaCompra"); */
    },
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    obtenerTiposPagos() {
      this.tipoPagoService.getAllTipoPagos().then((response) => {
        this.tipo_pagos = response.tipo_pagos;
      });
    },
    closeModal() {
      this.nuevaFechaVence = false;
    },
    agregarFechaVencimiento(producto) {
      this.nuevaFechaVence = true;
      this.productoFechas = producto;
    },
    agregarProductoDetalle() {
      if (this.verificarProductoLista()) {
        return false;
      }

      let datos = {
        producto_id: this.productoSeleccionado.id,
        sucursal_id: this.sucursalSelect,
      };

      this.ordenCompraService
        .getprecio_compra_producto(datos)
        .then((response) => {
          let producto = {
            id: this.productoSeleccionado.id,
            codigo_barra: this.productoSeleccionado.codigo_barra,
            codigo_interno: this.productoSeleccionado.codigo_interno,
            descripcion: this.productoSeleccionado.descripcion,
            fabrica_id: this.productoSeleccionado.fabrica_id,
            fechas_vencimiento: [],
            pivot: {
              producto_id: this.productoSeleccionado.id,
              cantidad: null,
              precio: parseFloat(response.stock.precio_compra || 0),
              margen: parseFloat(
                response.stock.precio_venta - response.stock.precio_compra || 0
              ),
              margen_porcentaje: parseFloat(
                ((response.stock.precio_venta - response.stock.precio_compra) /
                  response.stock.precio_compra) *
                  100 || 0
              ),
              descuento: 0,
              precio_venta: 0,
              pv2: parseFloat(response.stock.pv2 || 0),
              pv3: parseFloat(response.stock.pv3 || 0),
              sub_total: 0,
            },
          };
          this.productos.push(producto);
          this.productoSeleccionado = null;
        });
    },
    verificarProductoLista() {
      let producto = this.productos.find((producto) => {
        return producto.id == this.productoSeleccionado.id;
      });

      if (producto) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya se encuentra en la lista",
          life: 3000,
        });
        return true;
      }
      return false;
    },
    quitar(producto) {
      this.$confirm.require({
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        message: `¿ESTÁ SEGURO DE QUITAR EL PRODUCTO ${producto.data.descripcion} DEL RECIBO DE COMPRA?`,
        acceptLabel: "Quitar",
        rejectLabel: "Cancelar",
        acceptClass: "p-button-danger p-button-lg",
        rejectClass: "p-button-success p-button-lg",
        acceptIcon: "pi pi-trash",
        rejectIcon: "pi pi-check",
        accept: () => {
          this.productos.splice(producto.index, 1);
          this.calcular_total();
        },
      });
    },
    obtenerDatosOc() {
      this.ordenCompraService
        .obtenerOrdenCompra(this.ocId)
        .then((response) => {
          this.datosOc = response.oc;
          this.proveedorSelect = this.datosOc.proveedor_id;
          this.productos = response.productos;
          if (this.datosOc.estado != 1) {
            this.recibido = true;
          }

          this.productos.forEach((producto) => {
            producto.fechas_vencimiento = [];
            producto.pivot.precio = parseFloat(producto.pivot.precio) || 0;
            producto.pivot.cantidad = parseFloat(producto.pivot.cantidad) || 0;
            producto.pivot.margen =
              parseFloat(producto.precio_sugerido - producto.pivot.precio) || 0;
            producto.pivot.margen_porcentaje =
              parseFloat(
                (producto.pivot.margen / producto.pivot.precio) * 100
              ).toFixed(2) || 30;
            producto.pivot.precio_venta = 0;
            producto.pivot.descuento = 0;
            producto.pivot.sub_total =
              parseFloat(producto.pivot.precio) *
              parseFloat(producto.pivot.cantidad);
            this.calcularMargen(producto);
            this.calcularPrecioVenta(producto);
            if (this.aplicaRedondeoPrecioVenta) {
              this.redondearPrecioVenta(producto);
            }
            this.calcular_total();
          });
        })
        .then(() => {
          this.calcular_total();
        })
        .catch((error) => console.log(error));
    },

    cargarProductosProveedor() {
      this.proveedorService
        .obtenerLaboratorios(this.datosOc.proveedor_id)
        .then((response) => {
          this.grupoProductos = [];
          this.laboratorios = response.fabricas;

          this.laboratorios.forEach((laboratorio) => {
            let items = [];

            laboratorio.productos.forEach((producto) => {
              let item = {
                label: producto.descripcion,
                value: producto.id,
                data: producto,
              };
              items.push(item);
              this.cantidadProductosProveedor++;
            });

            let tmp = {
              label: laboratorio.nombre,
              code: laboratorio.id,
              items: items,
            };
            this.grupoProductos.push(tmp);
          });

          this.cargandoDatos = false;
        })
        .catch((error) => {
          console.log(error);
          this.cargandoDatos = false;
        });
    },
    calcular_descuento_total(watch = null) {
      let total_descuento = 0;
      this.productos.forEach((item) => {
        if (item.pivot.cantidad == 0 || item.pivot.cantidad == null) {
          return;
        }
        total_descuento += item.pivot.descuento;
        this.calcular_precio_unitario_descuento(item);
      });
      if (watch) {
        this.descuento_oc = total_descuento;
      }
    },
    calcular_total() {
      this.total_oc = 0;
      this.productos.forEach((item) => {
        if (item.pivot.cantidad == 0 || item.pivot.cantidad == null) {
          return;
        }
        this.total_oc += item.pivot.sub_total;
      });

      if (this.tipo_pagoSelect == 1) {
        this.efectivo = this.total_oc - this.descuento_oc;
      }
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    verificarRecepcion() {
      let resultado = false;

      if (this.nro_recibo == null || this.nro_recibo == "") {
        this.$toast.add({
          severity: "error",
          summary: "Recibo o Factura",
          detail: "Debe introducir un numero de recibo o factura",
          life: 3000,
        });
        return resultado;
      }

      if (this.nuevaCompra) {
        if (this.proveedorSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Proveedor",
            detail: "Debe seleccionar un proveedor",
            life: 3000,
          });
          return resultado;
        }
      }

      if (this.sucursalSelect == null) {
        this.$toast.add({
          severity: "error",
          summary: "Sucursal",
          detail: "Debe seleccionar una sucursal",
          life: 3000,
        });
        return resultado;
      }
      if (this.productos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Productos",
          detail: "No existen productos para recibir",
          life: 3000,
        });
        return resultado;
      }

      resultado = this.productos.every((producto) => {
        if (producto.pivot.cantidad == 0) {
          this.$toast.add({
            severity: "error",
            summary: "Cantidad",
            detail: `El producto ${producto.descripcion} no tiene cantidad`,
            life: 3000,
          });
          return;
        }
        /* if (producto.pivot.precio == 0) {
          this.$toast.add({
            severity: "error",
            summary: "Precio!",
            detail: `El producto ${producto.descripcion} no tiene precio`,
            life: 3000,
          });
          return;
        } */
        return true;
      });

      return resultado;
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          this.sucursales = response;
          this.sucursalSelect = this.sucursales[0].id;
        })
        .catch((error) => console.log(error));
    },
    recibirCompra() {
      this.enviando = true;
      if (!this.verificarRecepcion()) {
        this.enviando = false;
        return false;
      }
      // verificamos si los productos tienen fechas de vencimiento y preguntamos si desea recibir sin fechas de vencimiento o no
      let productosSinFechaVencimiento = this.productos.filter(
        (producto) => producto.fechas_vencimiento.length == 0
      );

      if (productosSinFechaVencimiento.length > 0) {
        this.$confirm.require({
          header: "Confirmación",
          icon: "pi pi-exclamation-triangle",
          message:
            "¿ESTÁS SEGURO DE RECIBIR LA COMPRA SIN FECHAS DE VENCIMIENTO?",
          acceptLabel: "Si, Recibir",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          accept: () => {
            this.enviando = false;
            this.enviarOcRecepcion();
          },
          reject: () => {
            this.enviando = false;
          },
        });
      }
      // si todos los productos tienen fechas de vencimiento
      else {
        this.enviando = false;
        this.enviarOcRecepcion();
      }
    },
    enviarOcRecepcion() {
      this.enviando = true;
      let datos = {
        oc_id: this.ocId ? this.ocId : null,
        sucursal_id: this.sucursalSelect ? this.sucursalSelect : null,
        productos: this.productos ? this.productos : null,
        fecha_recepcion: this.fecha_recepcion,
        nro_recibo: this.nro_recibo,
        descuento_oc: this.descuento_oc,
        observacion: this.observacion,
        tipo_pago_id: this.tipo_pagoSelect,
        dias_credito: this.dias_credito,
        efectivo: this.efectivo,
        metodo_pago_id: this.metodo_pagoSelect,
        a_cuenta_metodo_pago: this.a_cuenta_metodo_pago,
        total_acuenta: this.total_acuenta,
        total_oc: this.total_oc - this.descuento_oc,
        nuevaCompra: this.nuevaCompra,
        proveedor_id: this.proveedorSelect,
      };
      this.ocRecepcionService
        .registrarReciboOc(datos)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Recibo de Compra!",
            detail: "Recibo de Compra Registrado Correctamente",
            life: 10000,
          });
          this.enviando = false;
          this.$router.push({ name: "compras" });
        })
        .catch((datos) => {
          this.$toast.add({
            severity: "error",
            summary: "Recibo de Compra!",
            detail: datos,
            life: 10000,
          });
          this.enviando = false;
        });
    },
    validarCantidad(data) {
      let calcular = false;

      if (data.pivot.cantidad <= 0 || data.pivot.cantidad == "") {
        this.$toast.add({
          severity: "error",
          summary: "Cantidad!",
          detail: `El producto ${data.descripcion} no tiene cantidad`,
          life: 3000,
        });
        data.pivot.cantidad = 1;
        return calcular;
      } else if (
        data.pivot.cantidad > data.stock_maximo &&
        data.stock_maximo > 0
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Stock Maximo!",
          detail: `El producto ${data.descripcion} supera el stock maximo ${data.stock_maximo}`,
          life: 3000,
        });
        //data.pivot.cantidad = 1;

        return calcular;
      }
      // vefico el precio de compra  que no sea 0
      /*  if (data.pivot.precio == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Precio!",
          detail: `El producto ${data.descripcion} no tiene precio`,
          life: 3000,
        });
        data.pivot.precio = 0;
        return calcular;
      } */

      return true;
    },
    calcularPrecioCompraBaseSubTotal(data) {
      data.pivot.precio = round(data.pivot.sub_total / data.pivot.cantidad, 2);

      this.calcularMargen(data);
      this.calcularPorcentajeMargen(data);
      this.calcularPrecioVenta(data);
      if (this.aplicaRedondeoPrecioVenta) {
        this.redondearPrecioVenta(data);
      }
    },

    calcularSubTotal(data) {
      data.pivot.sub_total = round(data.pivot.precio * data.pivot.cantidad, 2);
    },
    redondearPrecioVenta(data) {
      let number = data.pivot.precio_venta;
      let getDecimalVal = number.toString().indexOf(".");
      let decimalPart = number.toString().substring(getDecimalVal + 1);

      if (decimalPart >= 51) {
        data.pivot.precio_venta = round(data.pivot.precio_venta, 0);
      } else {
        data.pivot.precio_venta = round(data.pivot.precio_venta, 0);
      }

      if (data.pivot.precio_unitario_descuento === undefined) {
        data.pivot.precio_unitario_descuento = data.pivot.precio;
      }

      data.pivot.margen =
        data.pivot.precio_venta - data.pivot.precio_unitario_descuento;
      this.calcularPorcentajeMargen(data);
      this.calcularMargen(data);
    },

    asignandoPrecioVenta(producto) {
      if (
        producto.pivot.precio_venta < producto.pivot.precio_unitario_descuento
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Precio de venta!",
          detail: `El precio de venta no puede ser menor al precio de compra`,
          life: 10000,
        });
        producto.pivot.precio_venta =
          producto.pivot.precio + producto.pivot.margen;
      }

      if (producto.pivot.precio_unitario_descuento === undefined) {
        producto.pivot.precio_unitario_descuento = producto.pivot.precio;
      }

      producto.pivot.margen =
        producto.pivot.precio_venta - producto.pivot.precio_unitario_descuento;
      this.calcularPorcentajeMargen(producto);
    },
    onChangeEditandoCelda(e) {
      let { data, newValue, field } = e;
      // validar cantidad
      data[field] = newValue;
      if (this.validarCantidad(data)) {
        if (field == "pivot.precio_venta") {
          this.asignandoPrecioVenta(data);
        }

        if (field == "pivot.subtotal") {
          this.calcularPrecioCompraBaseSubTotal(data);
          this.calcular_precio_unitario_descuento(data);
          this.calcularPrecioVenta(data);
          if (this.aplicaRedondeoPrecioVenta) {
            this.redondearPrecioVenta(data);
          }
          this.determinarMargen(data);
          this.calcularPorcentajeMargen(data);
          this.calcular_total();
        }

        if (
          data.pivot.precio !== 0 &&
          includes(["pivot.precio", "pivot.cantidad"], field)
        ) {
          this.calcular_precio_unitario_descuento(data);
          this.calcularSubTotal(data);
          /* this.calcularMargen(data);
        this.calcularPorcentajeMargen(data); */
          this.calcularPrecioVenta(data);
          if (this.aplicaRedondeoPrecioVenta) {
            this.redondearPrecioVenta(data);
          }

          this.determinarMargen(data);
          this.calcularPorcentajeMargen(data);

          this.calcular_total();
        }

        if (
          data.pivot.precio !== 0 &&
          includes(["pivot.margen_porcentaje"], field)
        ) {
          this.calcularMargen(data);
          this.calcularPrecioVenta(data);
          if (this.aplicaRedondeoPrecioVenta) {
            this.redondearPrecioVenta(data);
          }
        }
        if (data.pivot.precio !== 0 && includes(["pivot.margen"], field)) {
          this.calcularPorcentajeMargen(data);
          this.calcularPrecioVenta(data);
          if (this.aplicaRedondeoPrecioVenta) {
            this.redondearPrecioVenta(data);
          }
        }

        if (field == "pivot.descuento") {
          this.calcularSubTotal(data);
          this.calcular_total();
          this.calcular_descuento_total(true);
          this.calcular_precio_unitario_descuento(data);
          this.calcularPrecioVenta(data);
          if (this.aplicaRedondeoPrecioVenta) {
            this.redondearPrecioVenta(data);
          }
          this.determinarMargen(data);

          this.calcularMargen(data);
          this.calcularPorcentajeMargen(data);
        }
      } else {
        e.preventDefault();
      }
    },

    calcular_precio_unitario_descuento(producto) {
      producto.pivot.precio_unitario_descuento = round(
        producto.pivot.precio -
          producto.pivot.descuento / producto.pivot.cantidad,
        2
      );
    },

    calcularPorcentajeMargen(producto) {
      let precio =
        producto.pivot.precio_unitario_descuento ?? producto.pivot.precio;
      producto.pivot.margen_porcentaje = round(
        (producto.pivot.margen * 100) / precio,
        2
      );
    },
    calcularMargen(producto) {
      let precio =
        producto.pivot.precio_unitario_descuento ?? producto.pivot.precio;
      producto.pivot.margen = round(
        (producto.pivot.margen_porcentaje * precio) / 100,
        2
      );
    },
    calcularPrecioVenta(producto) {
      let precio =
        producto.pivot.precio_unitario_descuento ?? producto.pivot.precio;
      producto.pivot.precio_venta = precio + producto.pivot.margen;
    },

    goBack() {
      this.$router.go(-1);
    },
    fechaActual() {
      return new Date().toLocaleDateString();
    },
    buscarProductoPorCodigoDescripcion() {
      setTimeout(() => {
        /* if (this.productoSeleccionado.length > 4) {
          this.buscando_producto = true; */
        let datos = {
          texto: this.productoSeleccionado,
          sucursal_id: this.sucursalSelect,
        };

        this.productService
          .buscar_x_codigo_nombre(datos)
          .then((data) => {
            this.productos_encontrados = data.productos;
            this.buscando_producto = false;
          })
          .catch((error) => {
            this.buscando_producto = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error,
              life: 3000,
            });
          });
        /* } */
      }, 250);
    },
    repartir_descuento_a_productos_detalle() {
      let total_descuento = this.descuento_oc;
      let total_compra = this.total_oc;

      let porcentaje_descuento = (total_descuento * 100) / total_compra;

      this.productos.forEach((producto) => {
        let sub_total = round(
          producto.pivot.precio * producto.pivot.cantidad,
          2
        );

        producto.pivot.descuento = round(
          (porcentaje_descuento * sub_total) / 100,
          2
        );

        this.calcularSubTotal(producto);

        this.calcular_precio_unitario_descuento(producto);

        this.calcularPrecioVenta(producto);
        if (this.aplicaRedondeoPrecioVenta) {
          this.redondearPrecioVenta(producto);
        }
        this.determinarMargen(producto);

        /* this.calcularMargen(producto); */
        this.calcularPorcentajeMargen(producto);
      });
    },
    determinarMargen(producto) {
      producto.pivot.margen = round(
        producto.pivot.precio_venta - producto.pivot.precio_unitario_descuento,
        2
      );
    },
  },
  watch: {
    descuento_oc() {
      if (this.tipo_pagoSelect == 1) {
        this.efectivo =
          this.total_oc - this.a_cuenta_metodo_pago - this.descuento_oc;
      } else {
        this.efectivo = 0;
      }
      this.calcular_total();
      this.calcular_descuento_total(false);
    },
    metodo_pagoSelect() {
      if (this.tipo_pagoSelect == 1) {
        if (this.metodo_pagoSelect == 1) {
          this.a_cuenta_metodo_pago = 0;
          this.efectivo = this.total_oc - this.descuento_oc;
        } else {
          this.a_cuenta_metodo_pago = this.total_oc - this.descuento_oc;
          this.efectivo = 0;
        }
      } else {
        this.a_cuenta_metodo_pago = 0;
        this.efectivo = 0;
      }
    },
    tipo_pagoSelect() {
      if (this.tipo_pagoSelect == 1) {
        this.dias_credito = 0;
        this.efectivo = this.total_oc - this.descuento_oc;
      } else {
        this.a_cuenta_metodo_pago = 0;
        this.dias_credito = 30;
        this.efectivo = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/demo/badges.scss";
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
.p-invalid {
  border-color: #ff0000;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 0.5em;
  border: 1px solid #000000;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
